import Swiper from 'swiper/bundle';

export const initTestimonialsSlider = () => {
    new Swiper('[data-testimonials-slider]', {
        loop: true,
        centeredSlides: true,
        spaceBetween: 16,
        pagination: {
            el: '[data-testimonials-slider-pagination]',
            type: 'fraction',
        },
        navigation: {
            nextEl: '[data-testimonials-slider-button-next]',
            prevEl: '[data-testimonials-slider-button-prev]',
        },
        breakpoints: {
            0: {
                slidesPerView: 1.5,
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1040: {
                slidesPerView: 3.5,
            },
            1280: {
                slidesPerView: 3,
                centeredSlides: false,
                spaceBetween: 22,
            },
        },
    });
};
